.BusinessCard {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(120deg, #eee, #ddd);
    display: grid;
    place-items: center;
}

.card {
    margin-top: -20vh;
    --cardHeight: 220px;
    --cardWidth: 350px;
    width: var(--cardWidth);
    height: var(--cardHeight);
    background-color: transparent;
    perspective: 1000px;
}

.cardInner {
    width: 100%;
    height: 100%;
    transition: 0.6s transform;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background: transparent;
    border-radius: 20px;
}

.cardFront, .cardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 20px;
    overflow: hidden;
}

.cardFront {
    background: linear-gradient(90deg, #222, #333);
}

.cardBack {
    transform: rotateY(180deg);
    background: linear-gradient(90deg, #333, #222);
    font-weight: lighter;
}

.card:hover .cardInner {
    transform: rotateY(180deg);
}

.BusinessCard .logoWrapper {
    width: 100%;
    height: var(--cardHeight);
    display: grid;
    place-items: center;
}

.BusinessCard .logo {
    width: 150px;
    color: white;
}

.BusinessCard .iLogo {
    position: absolute;
    top: 75%;
    left: 85%;
    width: 30px;
    color: whitesmoke;
}

.cardBack__Header {
    width: 100%;
    display: grid;
    place-items: center;
    color: whitesmoke;
    padding: 15px 15px 5px 15px ;
}

.cardBack__Header h2 {
    font-size: 16px;
    font-weight: 600;
}

.cardBack__Header h3 {
    font-size: 10px;
}

.cardBack__contactInfo {
    padding: 0 20px 20px 20px;
}

.cardBack__row {
}

.cardBack__row label {
    color: #888;
    font-size: 11px;
}

.cardBack__row p {
    color: whitesmoke;
    font-size: 12px;
}
