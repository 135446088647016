.DisclaimerWidget {
    position: fixed;
    top: 75vh;
    left: 50%;
    transform: translateX(-50%);
}

.DisclaimerWidget p {
    font-family: Cairo, sans-serif;
    font-size: 11px;
}